import React from 'react';
import ReactDom from 'react-dom/client';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { store } from '@redux/store';
import { Provider } from 'react-redux';
import App from './App';
import config from '@config';
import LoginView from '@components/_admin/entity/auth/LoginView';
import RegistrationView from '@components/_admin/entity/auth/RegistrationView';
import MyAccountView from '@components/_admin/entity/auth/MyAccountView';
import UserView from '@components/_admin/entity/auth/UserView';
import GroupView from '@components/_admin/entity/auth/GroupView';
import PermissionView from '@components/_admin/entity/auth/PermissionView';
import DashboardView from '@components/dashboard/DashboardView';
import AppSettingAddEditForm from '@components/settings/AppSettingsAddEditForm';
run();

function run()
{
    const rootElement = document.getElementById('root');
    
    if (rootElement != null)
    {
        /**
         * React-Router remarks:
         * my-account, farm etc. can be nested inside the "/" route because the element <App> has an Outlet in one of its child elements
         * add, edit cannot be nested inside "asset-types", because  AssetTypeView has not Outlet for it
         */
        const root = ReactDom.createRoot(rootElement);
        root.render(
            <Provider store={store}>
                <BrowserRouter basename={config.reactRouterBaseName}>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index={true} element={<DashboardView />} />
                            
                            <Route path="my-account" element={<MyAccountView />} />
                            <Route path="users" element={<UserView />} />
                            <Route path="groups" element={<GroupView />} />
                            <Route path="permissions" element={<PermissionView />} />

                            <Route path="settings" element={<AppSettingAddEditForm />} />
                        </Route>

                        <Route path="/login" element={<LoginView />} />
                        <Route path="/register" element={<RegistrationView />} />
                    </Routes>
                </BrowserRouter>
            </Provider>,
        );
    }
}