import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@xFrame4/components/Hooks';
import NextJsHelper from '@xFrame4/helpers/NextJsHelper';
import { useLoad } from '@xFrame4/components/Hooks';
import AddEditForm, { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';

interface AppSettingAddEditFormProps
{

}

const AppSettingAddEditForm: FunctionComponent<AppSettingAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const navigate = useNavigate();

    /** Load the settings. */
    useLoad(async () => {
        
    }, []);
    
    const onValidate = () =>
    {
        return true;
    }

    /** Save the settings. */
    const onSave = async () =>
    {
        return true;
    }

    /** Tab: general */
    let cmpTabGeneral =
        <>
        </>;

    /** Render */
    return (
        <>
            <AddEditForm 
                mode={AddEditFormMode.Edit}
                title={t('APP_SETTINGS')}
                onValidate={() => onValidate()}
                onSave={() => onSave()}
                onSaveSuccess={() => NextJsHelper.revalidate()}
                onClose={() => navigate('/')}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </AddEditForm>
        </>
    );
}

export default AppSettingAddEditForm;