import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@components/_admin/entity/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import TabContainer from '@components/_admin/entity/TabContainer';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import Permission from '@xFrame4/business/users/Permission';

export interface PermissionAddEditFormProps extends EntityAddEditFormProps<Permission>
{

}

/**
 * Component: add/edit form for permissions
 */
const PermissionAddEditForm: FunctionComponent<PermissionAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Permission>(props.entity ?? new Permission());

    /** Tab: general */
    let cmpTabGeneral =
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="codename"
                        label={t('CODENAME')}
                        isRequired={true}
                        value={entity?.codename}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>;

    /** Render */
    return (
        <>
            <EntityAddEditForm<Permission>
                {...props}
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('PERMISSION').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default PermissionAddEditForm;