import { FunctionComponent } from 'react';
import Permission from '@xFrame4/business/users/Permission';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useOnAddEdit } from '../EntityViewHooks';
import React from 'react';
import { GridColumns } from '@mui/x-data-grid';
import EntityView from '@components/_admin/entity/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { AddEditFormMode } from '@components/_admin/entity/AddEditForm';
import PermissionAddEditForm from './PermissionAddEditForm';

export interface PermissionsViewProps
{

}

/**
 * Component: view for permissions
 */
const PermissionsView: FunctionComponent<PermissionsViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<Permission>(Permission);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<Permission>>(
        () => [
            { field: 'id', headerName: t('ID'), width: 50 },
            { field: 'codename', headerName: t('CODENAME'), type: 'string', flex: 1 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
                <PermissionAddEditForm
                    entity={addEditEntity}
                    afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
                />
            }

            <EntityView<Permission>
                id="permissions"
                title={t('PERMISSIONS')}
                manager={Permission.manager}
                baseFilter={{}}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'codename_Icontains', label: t('CODENAME'), type: FieldEditorType.Text},
                    {field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text},
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.codename + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default PermissionsView;